import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'rebass';
import { scrollToPosition } from '../../services/scroller'
import { Grid, Container, Column } from '../Layout';
import {
  HomeLink,
  List,
  ListItem,
  Nav,
  NavLink,
  Wrapper
} from './Header.style';

const Header = ({ location, siteTitle }) => {
  const data = useStaticQuery(graphql`
      query GET_MENU_ITEMS {
          wp {
              menus(where: {slug: "main-menu"}) {
                  nodes {
                      menuItems {
                          nodes {
                              ...MenuItem
                              childItems {
                                  nodes {
                                      ...MenuItem
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `);

  const isHomePage = location && location.pathname === '/';
  const menuItems = data.wp && data.wp.menus.nodes && data.wp.menus.nodes.length > 0 && data.wp.menus.nodes[0].menuItems

  const handleItemClick = (name) => {
    const el = document.getElementById(name.slice(1));

    if (!el) {
      return;
    }
    const position = el.offsetTop || 0;
    scrollToPosition(position);
  };

  return (
    <Wrapper>
      <Container>
        <Grid>
          <Column width={[12, 12, 6, 3]}>
            <HomeLink to="/">
              <Text color="offblack" as='h4' sx={{textTransform: 'uppercase'}} fontWeight='bold'>Jacek<Text as="span" color="secondary">Dabkiewicz</Text></Text>
            </HomeLink>
          </Column>
          <Column width={[12, 12, 6, 9]}>
            <Nav>
              <List>
                {menuItems && menuItems.nodes && menuItems.nodes.map(menuItem => {
                  if (isHomePage) {
                    return (
                      <ListItem key={menuItem.id} onClick={() => handleItemClick(menuItem.url)}>
                        {menuItem.label}
                      </ListItem>
                    )
                  }

                  return (
                    <ListItem key={menuItem.id}>
                      <NavLink to={menuItem.url}>{menuItem.label}</NavLink>
                    </ListItem>
                  )
                })}
              </List>
            </Nav>
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

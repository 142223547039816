import React from 'react'
import PropTypes from 'prop-types'

const HtmlRenderer = ({ html, ...rest }) => {
  const copy = html.replace(/\\n/g, '\n');

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: copy,
      }}
      {...rest}
    />
  )
};

HtmlRenderer.propTypes = {
  html: PropTypes.string,
};

HtmlRenderer.defaultProps = {
  html: '',
};

export default HtmlRenderer;

import { Link  } from 'gatsby';
import OutboundLink from '../components/OutboundLink';

export const getLinkTag = (uri) => {
  if (!uri) {
    return {
      tag: 'button',
      tagProps: {},
    };
  }

  const isExternalLink = uri.indexOf('http') >= 0;
  const Tag = isExternalLink ? OutboundLink : Link;
  const internalUri = uri[0] === '/' ? uri : `/${uri}`;
  const tagProps = isExternalLink ? { href: uri } : { to: internalUri };
  return ({
    isExternalLink,
    tag: Tag,
    tagProps,
  });
};

export const trackEvent = (category, action, label) => {
  if (!windowExists || !window.ga) {
    return;
  }
  const eventLabel = label || window.location.pathname;
  // eslint-disable-next-line no-undef
  ga('send', 'event', category, action, eventLabel);
};

export const windowExists = typeof window !== 'undefined';

import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'emotion-theming';
import CookieConsent from 'react-cookie-consent';

const CookieBox = () => {
  const { colors, space } = useTheme();

  return (
    <CookieConsent
      expires={90}
      style={{background: colors.offblack}}
      buttonStyle={{borderRadius: space.xsmall, color: colors.white, background: colors.primary, padding: `${space.small} ${space.medium}`, fontSize: '1rem'}}
    >
      This website uses cookies to improve your browsing experience and for analytics about visitors on this website. <Link to="/privacy-policy">Read more</Link>
    </CookieConsent>
  )
};

export default CookieBox;

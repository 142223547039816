import colors from './colors'
import space from './space'
import breakpoints from './breakpoints'
import mediaQueries from './mediaQueries'
import typography from './typography'

export default {
  breakpoints,
  colors,
  mediaQueries,
  space,
  typography,
};

export { colors }
export { space }
export { breakpoints }
export { mediaQueries }
export { typography }


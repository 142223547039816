import styled from '@emotion/styled';
import { colors, space } from '../../styles/theme';
import mediaQueries from '../../styles/mediaQueries';

export const Icon = styled('div')({
  position: 'relative',
  transition: 'all 0.3s ease-in-out',

  'i': {
    fontSize: '1.5rem',
  },
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  bottom: space.large,
  right: space.base,
  zIndex: 12,
  width: space.xxlarge,
  height: space.xxlarge,
  borderRadius: '50%',
  background: colors.white,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  boxShadow: 'rgba(48, 52, 63, 0.2) 0px 3px 6px 0px',

  [mediaQueries.small]: {
    width: '3.75rem',
    height: '3.75rem',
    right: space.large,
  },

  [`&:hover ${Icon}`] : {
    [mediaQueries.small]: {
      transform: `translateY(-${space.xsmall})`,
      color: colors.primary,
    },
  },
});
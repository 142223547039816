import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Box } from 'rebass';
import { colors, space } from '../../styles/theme';
import mediaQueries from "../../styles/mediaQueries";

export const Wrapper = styled('header')({
  padding: `${space.base} 0`,
  borderBottom: `1px solid ${colors.greymid}`
});

export const List = styled('ul')({
  listStyleType: 'none',
  display: 'none',
  padding: 0,
  margin: 0,

  [mediaQueries.small]: {
    display: 'inline-block',
  },
});

export const ListItem = styled('li')({
  listStyleType: 'none',
  display: 'inline-block',
  padding: 0,
  marginLeft: space.large,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  fontWeight: 600,

  '&:hover': {
    color: colors.secondary,
  }
});

export const Nav = styled(Box)({
  textAlign: 'right',
});

export const NavLink = styled(Link)({
  fontWeight: 600,
  padding: 0,
  color: colors.offblack,
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    textDecoration: 'none',
    color: colors.secondary,
  }
});

export const HomeLink = styled(Link)({
  '&:hover': {
    textDecoration: 'none',
  }
});

import React from 'react';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import theme from '../styles/theme';
import { ThemeProvider } from 'emotion-theming';

import CookieBox from '../components/CookieBox';
import Footer from '../components/Footer';
import Header from '../components/Header';
import GlobalStyles from '../components/GlobalStyles';
import ScrollToTop from '../components/ScrollToTop';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          wp {
              generalSettings {
                  title
              }
          }
      }
  `);

  const { title } = data.wp.generalSettings;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|Spartan:400,700&display=swap" rel="stylesheet" />
        <link href={withPrefix('css/fontawesome.min.css')} rel="stylesheet" />
        <link href={withPrefix('css/brands.min.css')} rel="stylesheet" />
        <link href={withPrefix('css/light.min.css')} rel="stylesheet" />
      </Helmet>
      <GlobalStyles />
      <Header siteTitle={title} location={location} />
      <main>{children}</main>
      <ScrollToTop />
      <Footer />
      <CookieBox />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react';
import PropTypes from 'prop-types';

import { trackEvent } from '../../utils';

function OutboundLink(props) {
  return (
    <a
      href={props.href}
      target={props.target}
      className={props.className}
      style={props.style}
      onClick={(e) => {
        if (typeof props.onClick === 'function') {
          props.onClick(e);
        }
        let redirect = true;
        if (
          e.button !== 0
          || e.altKey
          || e.ctrlKey
          || e.metaKey
          || e.shiftKey
          || e.defaultPrevented
        ) {
          redirect = false;
        }
        if (props.target && props.target.toLowerCase() !== '_self') {
          redirect = false;
        }

        if (props.gtmEventName) {
          trackEvent(props.gtmEventName, props.label);
        }

        if (redirect) {
          document.location = props.href;
        }

        return false;
      }}
    >
      {props.children}
    </a>
  );
}

export default OutboundLink;


OutboundLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  gtmEventName: PropTypes.string,
  label: PropTypes.string,
};

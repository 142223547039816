import React from 'react';
import PropTypes from 'prop-types';
import { getLinkTag, trackEvent } from '../../utils';
import { Wrapper } from './Button.style';


const Button = ({
  children,
  endIcon,
  gtmEventName,
  gtmEventType,
  inverted,
  onClick,
  size,
  startIcon,
  targetBlank,
  url,
  variant,
  ...rest
}) => {
  let Tag = 'button';

  const linkInformation = getLinkTag(url);
  Tag = linkInformation.tag;
  const tagProps = linkInformation.tagProps || {};

  if (gtmEventName) {
    const eventType = gtmEventType || 'Button';

    if (linkInformation.isExternalLink) {
      tagProps.gtmEventName = eventType;
      tagProps.label = gtmEventName;
    } else {
      tagProps.onClick = function () {
        trackEvent(eventType, gtmEventName);
      };
    }
  }

  if (targetBlank) {
    tagProps.target = '_blank';
    tagProps.rel = 'noopener noreferrer';
  }

  if (onClick) {
    tagProps.onClick = onClick;
  }

  return (
    <Tag {...tagProps}>
      <Wrapper variant={variant}>
        {children}
      </Wrapper>
    </Tag>
  )
};

Button.propTypes = {
  children: PropTypes.string,
  url: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  variant: PropTypes.oneOf(['primary', 'secondary', 'link']),
  size: PropTypes.oneOf(['large', 'small']),
  inverted: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  url: '/',
  startIcon: null,
  endIcon: null,
  variant: 'primary',
  size: 'large',
  inverted: false,
};

export default Button;
import React from 'react';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../services/scroller';
import { Icon, Wrapper } from './ScrollToTop.style';

const ScrollToTop = ({ icon }) => {
  return (
    <Wrapper onClick={scrollToTop}>
      <Icon>
        <i className={`fal ${icon}`} />
      </Icon>
    </Wrapper>
  )
};

ScrollToTop.propTypes = {
  icon: PropTypes.string,
};

ScrollToTop.defaultProps = {
  icon: 'fa-chevron-up',
};

export default ScrollToTop;
// Calculating the fluid font styles
const fluidFont = (min, max, minVw = 36, maxVw = 62) =>
  `calc(${min}rem + (${max} - ${min}) * ((100vw - ${minVw}rem) / (${maxVw} - ${minVw})) )`

export const sizes = {
  large: {
    fontSize: '1.25rem',
  },
  regular: {
    fontSize: '1.125rem',
  },
  medium: {
    fontSize: '0.875rem',
  },
  small: {
    fontSize: '0.75rem',
  },
};

export const fonts = {
  primary: '"Spartan", sans-serif',
  secondary: '"Open Sans", sans-serif',
};

export const lineHeight = 1.75;

export const typography = {
  // body
  body: {
    mobile: {
      ...sizes.medium,
      margin: 'auto',
    },
    tablet: {
      fontSize: fluidFont(0.8888888, 1.125),
    },
    desktop: {
      ...sizes.regular
    },
  },

  heading: {
    // h1
    primary: {
      mobile: {
        fontSize: '2.25rem',
        lineHeight: `${lineHeight * 1.6}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: `${lineHeight}rem`,
      },
      tablet: {
        fontSize: fluidFont(2.25, 3.1875),
        lineHeight: fluidFont(lineHeight * 2, lineHeight * 2),
        marginTop: fluidFont(lineHeight, lineHeight),
        marginBottom: fluidFont(lineHeight, lineHeight),
      },
      desktop: {
        fontSize: '3.1875rem',
        lineHeight: `${lineHeight * 2}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: `${lineHeight}rem`,
      },
    },
    // h2
    secondary: {
      mobile: {
        fontSize: '1.5rem',
        lineHeight: `${lineHeight * 1.5}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: `${lineHeight}rem`,
      },
      tablet: {
        fontSize: fluidFont(1.5, 1.75),
        lineHeight: fluidFont(lineHeight * 2, lineHeight * 2),
        marginTop: fluidFont(lineHeight, lineHeight),
        marginBottom: fluidFont(lineHeight, lineHeight),
      },
      desktop: {
        fontSize: '1.75rem',
        lineHeight: `${lineHeight * 2}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: `${lineHeight}rem`,
      },
    },
    // h3
    tertiary: {
      mobile: {
        fontSize: '1rem',
        lineHeight: `${lineHeight}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: '0',
      },
      tablet: {
        fontSize: fluidFont(1, 1.3125),
        lineHeight: fluidFont(lineHeight, lineHeight),
        marginTop: fluidFont(lineHeight, lineHeight),
      },
      desktop: {
        fontSize: '1.3125',
        lineHeight: `${lineHeight}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: '0',
      },
    },
    // h4
    quaternary: {
      mobile: {
        fontSize: '1rem',
        lineHeight: `${lineHeight}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: '0',
      },
      tablet: {
        fontSize: fluidFont(1, 1),
        lineHeight: fluidFont(lineHeight, lineHeight),
        marginTop: fluidFont(lineHeight, lineHeight),
      },
      desktop: {
        fontSize: '1rem',
        lineHeight: `${lineHeight}rem`,
        marginTop: `${lineHeight}rem`,
        marginBottom: '0',
      },
    },
  },
};

export default typography;

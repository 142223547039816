import styled from '@emotion/styled';
import { colors, space } from '../../styles/theme';
import mediaQueries from "../../styles/mediaQueries";

export const Wrapper = styled('section')(
  ({ variant }) => ({
    background: variant === 'primary' ? colors.white : colors.offwhite,
    paddingBottom: space.xlarge,

    [mediaQueries.medium]: {
      paddingBottom: space.xxxlarge,
      paddingTop: space.xxlarge,
    },
  })
);

export const TextWrapper = styled('div')(
  ({ textAlign }) => ({
    textAlign: 'left',

    [mediaQueries.small]: {
      textAlign,
    },
  })
);

export const Title = styled('h2')({
  position: 'relative',
  display: 'inline-block',

  '&::after': {
    content: '""',
    position: 'absolute',
    height: '3px',
    width: space.xxlarge,
    background: colors.secondary,
    left: '0',
    bottom: space.xsmall,

    [mediaQueries.small]: {
      bottom: '0.667rem',
    },
  }
});
const colors = {
  white: '#FFFFFF',
  offwhite: '#f2f4f7',
  greymid: '#D2D2D2',
  greydark: '#707070',
  offblack: '#30343f',
  black: '#000000',
  success: '#008810',
  error: '#EB0023',
  primary: '#BC818C',
  secondary: '#4B5F86',
};

export default colors;

import styled from '@emotion/styled'
import {
  variant as _variant,
  space as styledSpace,
  layout,
} from 'styled-system'

import { Box } from 'rebass'

import mediaQueries from '../../styles/mediaQueries'

export const Wrapper = styled(Box)(
  ({ theme: { space, colors } }) => ({
    borderRadius: space.xsmall,
    display: 'inline-block',
    textAlign: 'center',
    margin: '0',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: '400',
    transform: 'none',
    lineHeight: '1.5',
    padding: `${space.small} ${space.base}`,
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    border: '1px solid',

    '&:disabled': {
      background: colors.greymid,
      border: 'none',
      cursor: 'not-allowed',

      '&:hover': {
        background: colors.greymid,
        color: colors.white,
        border: 'none',
      },
    },

    [mediaQueries.small]: {
      width: 'auto',
    },
  }),

  ({ theme: { colors } }) =>
    _variant({
      variants: {
        primary: {
          background: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
          svg: {
            stroke: colors.white,
            transition: 'all 0.2s ease-in-out',
          },
          '&:hover': {
            background: colors.secondary,
            borderColor: colors.secondary,

            svg: {
              stroke: colors.black,
            },
          },
        },
        secondary: {
          background: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
          svg: {
            stroke: colors.white,
            transition: 'all 0.2s ease-in-out',
          },
          '&:hover': {
            color: colors.black,
            svg: {
              stroke: colors.black,
            },
          },
        },
      },
    }),

  // ({ theme: { button, space } }) =>
  //   space &&
  //   button &&
  //   _variant({
  //     prop: 'size',
  //     variants: {
  //       large: {
  //         minHeight: space[button.large.height],
  //         height: space[button.large.height],
  //         fontSize: button.large.fontSize,
  //       },
  //       small: {
  //         minHeight: space[button.small.height],
  //         height: space[button.small.height],
  //         fontSize: button.small.fontSize,
  //       },
  //     },
  //   }),
  styledSpace,
  layout
)

export const ButtonText = styled('span')(
  styledSpace,
  ({ theme: { button, fonts }, variant }) => ({
    whiteSpace: 'nowrap',
    marginTop: button.textMargin,
    borderBottom: '2px solid',
    borderBottomColor: 'transparent',
    transition: 'border-bottom-color 0.3s ease-in-out',
    fontFamily:
      (button && fonts[button.fontFamily]) || variant === 'link'
        ? fonts.body
        : fonts.heading,
  }),
  ({ theme: { colors, button }, inverted }) =>
    _variant({
      variants: {
        primary: {
          'button:hover &': {
            borderBottomColor: inverted
              ? colors[button.hoverBorderBottomInvPrimary]
              : colors[button.hoverBorderBottomPrimary],
          },
        },
        secondary: {
          'button:hover &': {
            borderBottomColor: inverted
              ? colors[button.hoverBorderBottomInvSecondary]
              : colors[button.hoverBorderBottomSecondary],
          },
        },
        link: {
          'button:hover &': {
            borderBottomColor: inverted
              ? colors[button.hoverBorderBottomInvLink]
              : colors[button.hoverBorderBottomLink],
          },
        },
      },
    })
)

const space = [
  '0',
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '3rem',
  '4.5rem',
];

space.none = space[0]; // 0px
space.xsmall = space[1]; // 4px
space.small = space[2]; // 8px
space.medium = space[3]; // 12px
space.base = space[4]; // 16px
space.large = space[5]; // 24px
space.xlarge = space[6]; // 32px
space.xxlarge = space[7]; // 48px
space.xxxlarge = space[8]; // 72px

export default space;

import { css } from '@emotion/core';
import { normalize } from 'emotion-normalize';
import mediaQueries from './mediaQueries';
import { typography, lineHeight } from "./typography";

const globalStyles = (colors, space) =>
  css((normalize, {
    ':root': {
      /* Setting 1rem = 18px */
      fontSize: '16px',
    },

    '*': {
      boxSizing: 'border-box',
    },

    html: {
      height: '100%',
      padding: '0',
      margin: '0',
    },

    body: {
      fontFamily: '"Open Sans", sans-serif',
      lineHeight: `${lineHeight}rem`,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      color: colors ? colors.offblack : 'initial',
      padding: '0',
      margin: '0',
      ...typography.body.mobile,

      [mediaQueries.small]: {
        ...typography.body.tablet,
      },
      [mediaQueries.large]: {
        ...typography.body.desktop,
      },
    },

    a: {
      textDecoration: 'none',
      transition: 'all 0.3s ease-in-out',
    },

    'a, a:hover, a:focus, a:active, a:focus:active': {
      color: colors.primary,
    },

    'a:hover': {
      textDecoration: 'underline',
    },

    img: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
    },

    svg: {
      display: 'block',
    },

    figure: {
      margin: `${space.large} 0`,
    },

    // table: {
    //   border: `1px solid ${colors.greymid}`,
    //   borderCollapse: 'collapse',
    //   borderSpacing: 0,
    //   marginTop: space.medium,
    //   marginBottom: space.large,
    //   textAlign: 'left',
    //   display: 'block',
    //   overflowX: 'auto',
    //   whiteSpace: 'nowrap',
    //   width: 'fit-content',
    //   maxWidth: '100%',
    //
    //   [mediaQueries.large]: {
    //     display: 'table',
    //     overflowX: 'visible',
    //     whiteSpace: 'normal',
    //   },
    //
    //   'thead th': {
    //     backgroundColor: colors.primary,
    //     border: `1px solid ${colors.greymid}`,
    //     color: `${colors.white} !important`,
    //   },
    //
    //   th: {
    //     padding: space.small,
    //     textAlign: 'left',
    //
    //     [mediaQueries.small]: {
    //       minWidth: '15rem',
    //       padding: `${space.medium} ${space.medium}`,
    //     },
    //   },
    //
    //   'tbody td': {
    //     border: `1px solid ${colors.greymid}`,
    //     padding: space.small,
    //     textAlign: 'left',
    //
    //     [mediaQueries.small]: {
    //       minWidth: '15rem',
    //       padding: `${space.small} ${space.medium}`,
    //     },
    //   },
    //
    //   // Scroll bar styling
    //   scrollbarWidth: 'thin', // For Firefox
    //   scrollbarColor: `${colors.primary} transparent`, // For Firefox
    //
    //   // For Chrome/Safari
    //   '&::-webkit-scrollbar': {
    //     backgroundColor: 'transparent',
    //     height: '8px',
    //   },
    //
    //   '&::-webkit-scrollbar-track': {
    //     backgroundColor: 'transparent',
    //   },
    //
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: colors.primary,
    //     borderRadius: '2rem',
    //   },
    // },

    // Global Typography
    h1: {
      fontFamily: '"Spartan", sans-serif',
      fontWeight: 'bold',
      ...typography.heading.primary.mobile,
      [mediaQueries.small]: {
        ...typography.heading.primary.tablet,
      },
      [mediaQueries.large]: {
        ...typography.heading.primary.desktop,
      },
    },

    h2: {
      fontFamily: '"Spartan", sans-serif',
      fontWeight: 'bold',
      ...typography.heading.secondary.mobile,
      [mediaQueries.small]: {
        ...typography.heading.secondary.tablet,
      },
      [mediaQueries.large]: {
        ...typography.heading.secondary.desktop,
      },
    },

    h3: {
      fontFamily: '"Spartan", sans-serif',
      fontWeight: 'bold',
      ...typography.heading.tertiary.mobile,
      [mediaQueries.small]: {
        ...typography.heading.tertiary.tablet,
      },
      [mediaQueries.large]: {
        ...typography.heading.tertiary.desktop,
      },
    },

    h4: {
      fontFamily: '"Spartan", sans-serif',
      fontWeight: 'bold',
      ...typography.heading.quaternary.mobile,
      [mediaQueries.small]: {
        ...typography.heading.quaternary.tablet,
      },
      [mediaQueries.large]: {
        ...typography.heading.quaternary.desktop,
      },
    },

    p: {
      marginBottom: '1.5rem',
    },
  }));

export default globalStyles;

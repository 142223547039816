import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Column } from '../Layout';
import { TextWrapper, Title, Wrapper } from './Section.style';

const Section = ({ children, justifyContent, fullWidth, subtitle, textAlign, title, variant, width, ...rest }) => {
  return (
    <Wrapper variant={variant} {...rest}>
      <Container fullwidth={fullWidth}>
        <Grid justifyContent={justifyContent}>
          <Column width={width}>
            <TextWrapper textAlign={textAlign}>
              {title && <Title>{title}</Title>}
              {subtitle && <h3>{subtitle}</h3>}
            </TextWrapper>
            {children}
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  )
};

Section.propTypes = {
  fullWidth: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  subtitle: PropTypes.string,
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  title: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
};

Section.defaultProps = {
  fullWidth: false,
  justifyContent: 'flex-start',
  subtitle: '',
  textAlign: 'left',
  title: '',
  variant: 'primary',
  width: 12,
};

export default Section;
import React from 'react';
import { Grid, Container, Column } from '../Layout';
import { Wrapper } from './Footer.style';

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <Column width={[12, 12, 12, 12]}>
            Jacek Dabkiewicz. Copyright © {new Date().getFullYear()}, All rights reserved
          </Column>
        </Grid>
      </Container>
    </Wrapper>

  )
};

export default Footer;

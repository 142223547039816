import React from 'react'
import PropTypes from 'prop-types'
import { Global } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import globalStyles from '../../styles/globalStyles'

const GlobalStyles = ({ children }) => {
  const { colors, space } = useTheme();
  return (
    <>
      <Global
        styles={{ ...globalStyles(colors, space) }}
      />
      {children}
    </>
  )
};

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

GlobalStyles.defaultProps = {
  children: null,
};

export default GlobalStyles;

import styled from '@emotion/styled';
import { colors, space } from '../../styles/theme';
import { sizes } from '../../styles/typography';

export const Wrapper = styled('footer')({
  position: 'relative',
  marginTop: '-1rem',
  padding: `${space.base} 0`,
  background: colors.offblack,
  color: colors.white,
  fontSize: sizes.medium,
});


import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { system } from 'styled-system';
import { Flex, Box } from 'rebass';

const StyledColumn = styled(Box)(
  system({
    width: {
      property: 'width',
      transform: (value, scale) =>
        value !== null ? `${scale[value] * 100}%` : null,
      defaultScale: [
        0,
        1 / 12,
        2 / 12,
        3 / 12,
        4 / 12,
        5 / 12,
        6 / 12,
        7 / 12,
        8 / 12,
        9 / 12,
        10 / 12,
        11 / 12,
        12 / 12,
      ],
    },
  })
);

const StackWrapper = styled('div')(({ theme: { space: margin }, space }) => ({
  '> *': {
    marginTop: `${margin[space]} !important`,

    '&:last-of-type': {
      marginBottom: `${margin[space]} !important`,
    },
  },
}));

export const Container = ({ fullwidth, ...props }) => {
  const containerSizes = {
    maxWidth: fullwidth ? '100%' : ['none', 'none', '992px', '1200px'],
  };

  return (
    <Box
      px={['base', null]}
      width="100%"
      mx="auto"
      {...containerSizes}
      {...props}>
      {props.children}
    </Box>
  )
};

export const Grid = (props) => {
  return (
    <Flex mx={[0, null, -3]} flexWrap="wrap" {...props}>
      {props.children}
    </Flex>
  )
};

export const Column = (props) => {
  const { order } = props;
  const sxProps = order ? { order } : {};
  return (
    <StyledColumn px={[0, null, 'medium']} sx={sxProps} {...props}>
      {props.children}
    </StyledColumn>
  )
};

export const Stack = ({ space, children }) => {
  return <StackWrapper space={space}>{children}</StackWrapper>
};

Stack.propTypes = {
  space: PropTypes.oneOf([
    'none',
    'xsmall',
    'small',
    'medium',
    'base',
    'large',
    'xlarge',
    'xxlarge',
    'xxxlarge',
  ]),
  children: PropTypes.node.isRequired,
}

Stack.defaultProps = {
  space: 'base',
}

import { animateScroll } from 'react-scroll';

const scrollingDefaults = {
  duration: 400,
  delay: 0,
  smooth: 'easeInOutQuart',
};

export const scrollToTop = (options = scrollingDefaults) => animateScroll.scrollToTop(options);

export const scrollToPosition = (position, options = scrollingDefaults) => animateScroll.scrollTo(position, options);
